<template>
	<registration-form></registration-form>
</template>

<script>
import RegistrationForm from "../components/common/RegistrationForm.vue";
export default {
	components: { RegistrationForm },
	name: "DonationFormLayout",
};
</script>

<style lang='scss'>
</style>