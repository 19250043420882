<template>
    <div class="calendarWrp" ref="calendarWrp">
        <div class="daysHead">
            <div v-for="index in 7" :key="index">
                {{ $t(`form.calendarDays.${index}`) }}
            </div>
        </div>
        <div class="days" v-if="!loading">
            <div
                class="day beforeday"
                v-for="index in firstDayName"
                :key="`db${index}`"
            >
                {{ daysBefore - (firstDayName - index) }}
            </div>
            <day-cell
                v-for="index in days"
                :key="`${getDate(index).toString()}`"
                :date="getDate(index)"
                :selectedDate="selectedDate"
                @click="onDaySelect"
                :workingDays="workingDays"
                :style="{ height: `${cellHeight}px` }"
            ></day-cell>
            <template v-if="lastDayName != 6">
                <div
                    class="day afterday"
                    v-for="index in 7 - (lastDayName + 1)"
                    :key="`ab${index}`"
                >
                    {{ index }}
                </div>
            </template>
        </div>
        <div v-else class="loadingFiller" key="ld">
            <div class="lds-dual-ring"></div>
        </div>
    </div>
</template>

<script>
import DayCell from "./DayCell.vue";
export default {
    components: { DayCell },
    name: "Calendar",
    props: ["date", "initialDate", "workingDays", 'loading'],
    data() {
        return {
            selectedDate: null,
            cellHeight: null
        };
    },
    methods: {
        getDate(date) {
            return new Date(this.date.setDate(date));
        },
        onDaySelect(e) {
            this.selectedDate = e.date;
            this.$emit("select", e);
        },
        setHeight() {
            this.cellHeight = this.$refs.calendarWrp.offsetWidth / 7;
        }
    },
    computed: {
        days() {
            if (this.date)
                return new Date(
                    this.date.getFullYear(),
                    this.date.getMonth() + 1,
                    0
                ).getDate();
            else return null;
        },
        daysBefore() {
            if (this.date)
                return new Date(
                    this.date.getFullYear(),
                    this.date.getMonth(),
                    0
                ).getDate();
            else return null;
        },
        firstDayName() {
            if (this.date)
                return new Date(
                    this.date.getFullYear(),
                    this.date.getMonth(),
                    1
                ).getUTCDay();
            else return null;
        },
        lastDayName() {
            if (this.date)
                return new Date(
                    this.date.getFullYear(),
                    this.date.getMonth() + 1,
                    0
                ).getUTCDay();
            else return null;
        }
    },
    created() {
        if (this.initialDate) this.selectedDate = this.initialDate;
    },
    mounted() {
        // this.date = new Date("2.1.2021");
        // this.date = new Date();
        this.setHeight();
        window.addEventListener("resize", this.setHeight);
    },
    destroyed() {
        window.removeEventListener("resize", this.setHeight);
    }
};
</script>

<style lang="scss">
.calendarWrp {
    margin-top: 30px;
    .loadingFiller {
        margin-top: 30px;
        .lds-dual-ring::after {
            border: 6px solid #f4a700;
            border-color: #f4a700 transparent #f4a700 transparent;
        }
    }
    .daysHead {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        border-bottom: 1px solid #8e8e93;
        margin-bottom: 5px;
        div {
            text-align: center;
            padding-bottom: 18px;
        }
    }
    .days {
        display: flex;
        flex-wrap: wrap;
        div {
            text-align: center;
            // padding: 17px;
            width: calc(100% / 7);
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 1px 0;
        }
        .beforeday,
        .afterday {
            color: #f39526;
            pointer-events: none;
        }
    }
}
</style>
