<template>
	<div class="mdbg" ref="bg" @click="onBgClick">
		<div class="formBody" v-if="formState == 'form'">
			<div class="stateBar">
				<div class="caption">{{ $t("form.stateBar.detaliVizitu") }}</div>
				<div class="dataItem">
					<div class="title">1. {{ $t("form.stateBar.kontaktDanni") }}</div>
					<div class="datatxt" v-if="name">
						<span>{{ name }}</span> <span>{{ phone }}</span>
					</div>
					<div class="nodata" v-else>{{ $t("form.stateBar.neVneseno") }}</div>
				</div>
				<div class="dataItem">
					<div class="title">2. {{ $t("form.stateBar.plasmacentr") }}</div>
					<div class="datatxt" v-if="plasmacenter">
						{{ plasmacenter.translate.address }}
					</div>
					<div class="nodata" v-else>{{ $t("form.stateBar.neVneseno") }}</div>
				</div>
				<div class="dataItem">
					<div class="title">3. {{ $t("form.stateBar.dataVizitu") }}</div>
					<div class="datatxt date" v-if="date">
						{{ dateTxt }}
					</div>
					<div class="nodata" v-else>{{ $t("form.stateBar.neVneseno") }}</div>
				</div>
				<div class="dataItem">
					<div class="title">4. {{ $t("form.stateBar.chas") }}</div>
					<div class="datatxt date" v-if="time">
						{{ time }}
					</div>
					<div class="nodata" v-else>{{ $t("form.stateBar.neVneseno") }}</div>
				</div>
			</div>
			<div class="formContent">
				<div class="container">
					<name-phone-slide
						v-if="currentStep == 'contacts'"
						@submitslide="onSubmitContactStep"
						@backStep="backStep"
						:initialFields="{ name: name, phone: phone }"
					></name-phone-slide>
					<plasmacenters-slide
						v-else-if="currentStep == 'plasmacenter'"
						@submitslide="onSubmitCentersStep"
						@backStep="backStep"
						:initialData="plasmacenter"
					></plasmacenters-slide>
					<calendar-slide
						v-else-if="currentStep == 'date'"
						:mounthCount="mounthCount"
						@submitslide="onSubmitCalendarStep"
						@backStep="backStep"
						:initialData="date"
						:plasmacenter="plasmacenter"
						@pushspots="onPushSpots"
						:datesjson="datesjson"
					></calendar-slide>
					<time-select-slide
						v-else-if="currentStep == 'time'"
						@submitslide="onSubmitTimeStep"
						@change="onChangeTimeStep"
						@backStep="backStep"
						:spots="spots"
						:interval="interval"
                        :centerId="plasmacenter.UId"
					></time-select-slide>
				</div>
			</div>
			<div class="closeWrp">
				<div class="mobStepInfo">
					<svg-circle :fillValue="circleVal" :theme="'dark'"></svg-circle>
					<div>{{ mobStepTxt }}</div>
				</div>
				<span class="ic-close-rounded" @click="closeForm"></span>
			</div>
		</div>
		<div class="formSuccess" v-else>
			<div class="closeWrp"></div>
			<div class="content">
				<div class="container">
					<img
						class="icn"
						:src="require(`@/assets/img/Group 874.svg`)"
						alt="123"
					/>
					<div class="title">{{ $t("form.formSuccess.title") }}</div>
					<div class="sub">{{ $t("form.formSuccess.sub") }}</div>
					<div class="tabl">
						<div class="cell">
							<div class="caption">{{ $t("form.stateBar.plasmacentr") }}</div>
							<div class="t1">
								{{ plasmacenter ? plasmacenter.translate.address : null }}
							</div>
						</div>
						<a
							v-if="plasmacenter"
							:href="`https://www.google.com/maps/place/${plasmacenter.model.coord_lat},${plasmacenter.model.coord_lng}`"
							class="mapsLink"
							target="_blank"
							><span class="ic-marker"></span
							>{{ $t("form.formSuccess.yakdobratis") }}</a
						>
						<span v-else>no data</span>
						<div class="cell">
							<div class="caption">{{ $t("form.stateBar.dataVizitu") }}</div>
							<div class="t2">{{ dateTxt }}</div>
						</div>
						<div class="cell">
							<div class="caption">{{ $t("form.stateBar.chas") }}</div>
							<div class="t2">{{ time }}</div>
						</div>
						<div class="cell">
							<div class="caption">{{ $t("form.formSuccess.shovzati") }}</div>
							<div class="t1">{{ $t("form.formSuccess.psikod") }}</div>
						</div>
					</div>
					<div class="controls">
						<router-link
							:to="{
								name: 'home',
								params: { locale: $i18n.locale == 'uk' ? null : $i18n.locale },
							}"
							class="toHome"
							>{{ $t("form.formSuccess.toTitle") }}</router-link
						>
						<router-link
							:to="{
								name: 'article',
								params: {
									locale: $i18n.locale == 'uk' ? null : $i18n.locale,
									slug: 'yak-pidgotuvatis-do-donaciji-plazmi-krovi',
								},
							}"
							class="toPage"
							>{{ $t("form.formSuccess.howToDonation") }}</router-link
						>
					</div>
				</div>
			</div>
			<div class="closeWrp">
				<span class="ic-close-rounded" @click="closeForm"></span>
			</div>
		</div>
		<div class="exitModal" v-if="exitModal">
			<div class="modalbody">
				<img
					class="icon"
					:src="require(`@/assets/img/modalexit.svg`)"
					alt="123"
				/>
				<div class="t1">{{ $t("form.exitModal.t1") }}</div>
				<div class="t2">{{ $t("form.exitModal.t2") }}</div>
				<div class="controls">
					<div class="btnExit" @click="closeForm">
						{{ $t("form.exitModal.exit") }}
					</div>
					<div class="btnStay" @click="exitModal = false">
						{{ $t("form.exitModal.stay") }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CalendarSlide from "./RegistrationFormParts/CalendarSlide.vue";
import NamePhoneSlide from "./RegistrationFormParts/NamePhoneSlide.vue";
import PlasmacentersSlide from "./RegistrationFormParts/PlasmacentersSlide.vue";
import TimeSelectSlide from "./RegistrationFormParts/TimeSelectSlide.vue";
import SvgCircle from "./SvgCircle.vue";
import Cookies from "js-cookie";
export default {
	components: {
		NamePhoneSlide,
		PlasmacentersSlide,
		CalendarSlide,
		TimeSelectSlide,
		SvgCircle,
	},
	name: "RegistrationFomr",
	data() {
		return {
			formSending: false,
			mounthCount: 4,
			exitModal: false,
			canExit: false,
			formState: "form",
			spots: null,
			interval: null,
			datesjson: null,

			currentStep: "contacts",
			request_id: null,
			name: "",
			phone: "",
			plasmacenter: null,
			date: null,
			time: null,
		};
	},
	methods: {
		nextStep() {
			let formData = {
				request_id: this.request_id,
				name: this.name,
				phone: this.phone,
				plasmacenter: this.plasmacenter,
				date: this.date,
			};
			window.localStorage.setItem("formData", JSON.stringify(formData));
			switch (this.currentStep) {
				case "contacts":
					this.axios
						.post("/api/request/new", {
							name: this.name,
							phone: this.phone,
							request_id: this.request_id || null,
						})
						.then((response) => {
							this.request_id = response.data.data.request_id;
							let formData = {
								request_id: this.request_id,
								name: this.name,
								phone: this.phone,
								plasmacenter: this.plasmacenter,
								date: this.date,
							};
							window.localStorage.setItem("formData", JSON.stringify(formData));
						});
					this.currentStep = "plasmacenter";
					break;
				case "plasmacenter":
					this.currentStep = "date";
					break;
				case "date":
					this.currentStep = "time";
					break;
				case "time":
					this.formSend();
					break;

				default:
					break;
			}
		},
		formSend() {
			if (!this.formSending) {
				this.formSending = true;
				let request = {
					request_id: this.request_id,
					name: this.name,
					phone: this.phone,
					plasmacenter_id: this.plasmacenter.model.id,
					date: `${Intl.DateTimeFormat("uk", { year: "numeric" }).format(
						this.date
					)}-${Intl.DateTimeFormat("uk", { month: "numeric" }).format(
						this.date
					)}-${Intl.DateTimeFormat("uk", { day: "numeric" }).format(
						this.date
					)}`,
					time: this.time,
					refCode: Cookies.get("refCode"),
					source: Cookies.get("source"),
				};

				this.axios.post("/api/request/send", request).then((response) => {
					if (response.data.success) {
						this.canExit = true;
						window.localStorage.removeItem("formData");
						this.formState = "formSuccess";
						this.formSending = false;
					} else {
						alert(
							"Не вдалося записатись на сеанс. Перейдіть до нашого віджету (https://widget.easyweek.io/biopharma-plasma)"
						);
						this.formSending = false;
						this.currentStep = "date";
					}
				});
			}
		},
		backStep() {
			switch (this.currentStep) {
				case "plasmacenter":
					this.currentStep = "contacts";
					break;
				case "date":
					this.currentStep = "plasmacenter";
					break;
				case "time":
					this.currentStep = "date";
					break;

				default:
					break;
			}
		},
		onSubmitContactStep(e) {
			this.name = e.name;
			this.phone = e.phone;
			this.nextStep();
		},
		onSubmitCentersStep(e) {
			this.plasmacenter = e;
			this.nextStep();
		},
		onSubmitCalendarStep(e) {
			this.date = e.selectedDate;
			this.datesjson = e.datesjson;
			this.nextStep();
		},
		onChangeTimeStep(e) {
			this.time = e;
		},
		onSubmitTimeStep(e) {
			this.time = e;
			this.nextStep();
		},
		onBgClick(e) {
			if (e.target.classList.contains("mdbg")) {
				this.closeForm();
			}
		},
		onPushSpots(e) {
			this.spots = e.spots;
			this.interval = e.interval;
		},
		closeForm() {
			if (this.canExit) {
				this.$router.go(-1);
			} else {
				this.exitModal = true;
				this.canExit = true;
			}
		},
	},
	computed: {
		dateTxt() {
			if (this.date) {
				return Intl.DateTimeFormat("uk", {
					year: "numeric",
					day: "numeric",
					month: "long",
				})
					.format(new Date(this.date))
					.match(/[0-9]{1,2} [А-Яа-яёЁЇїІіЄєҐґ]{1,20} [0-9]{4}/)[0];
			} else return "no date";
		},
		mobStepTxt() {
			switch (this.currentStep) {
				case "contacts":
					return "1 крок з 4";
				case "plasmacenter":
					return "2 крок з 4";
				case "date":
					return "3 крок з 4";
				case "time":
					return "4 крок з 4";
				default:
					return null;
			}
		},
		circleVal() {
			let all = 4;
			let cur;
			switch (this.currentStep) {
				case "contacts":
					cur = 1;
					break;
				case "plasmacenter":
					cur = 2;
					break;
				case "date":
					cur = 3;
					break;
				case "time":
					cur = 4;
					break;
				default:
					cur = 1;
					break;
			}
			let p = 75 - (cur * 75) / all + 77;
			return p;
		},
	},
	created() {
		let localJson = window.localStorage.getItem("formData");
		if (localJson) {
			let local = JSON.parse(localJson);
			if (local) {
				if (local.request_id) {
					this.request_id = local.request_id;
				}
				if (local.name) {
					this.name = local.name;
				}
				if (local.phone) {
					this.phone = local.phone;
				}
				if (local.plasmacenter) {
					this.plasmacenter = local.plasmacenter;
				}
				// if (local.date) {
				// 	let dtstr = local.date;
				// 	let date = new Date(dtstr);
				// 	let a = new Date();
				// 	let b = new Date(a.setMonth(a.getMonth() + this.mounthCount - 1));
				// 	if (date < b) {
				// 		this.date = date;
				// 	} else {
				// 		// alert('bad')
				// 	}
				// }
			}
		}
	},
	mounted() {
		if (this.$route.query.refCode) {
			Cookies.set("refCode", this.$route.query.refCode);
		}
		if (this.$route.query.source) {
			Cookies.set("source", this.$route.query.source);
		}
		
	},
};
</script>

<style lang="scss">
.mdbg {
	position: fixed;
	z-index: 99;
	bottom: 0;
	top: 0;
	right: 0;
	left: 0;
	@media (max-width: 960px) {
		position: static;
	}
}
.formBody {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background-color: #fff;
	background: #f4a700;
	z-index: 100;
	display: grid;
	grid-template-columns: 288px 1fr 56px;
	// overflow: overlay;
	@media (max-width: 960px) {
		grid-template-columns: 1fr;
		height: unset;
		position: static;
	}
	.stateBar {
		padding: 55px 32px 32px;
		@media (max-width: 960px) {
			display: none;
		}
		& > .caption {
			font-style: normal;
			font-weight: 600;
			font-size: 33px;
			line-height: 112%;
			color: #ffffff;
		}
		.dataItem {
			margin-top: 32px;
			.title {
				font-style: normal;
				font-weight: 600;
				font-size: 18.7437px;
				line-height: 112%;
				color: #ffffff;
			}
			.nodata {
				font-style: normal;
				font-weight: 600;
				font-size: 11.9278px;
				line-height: 112%;
				color: rgba(255, 255, 255, 0.64);
				margin-top: 8px;
			}
			.datatxt {
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 135%;
				color: rgba(0, 0, 0, 0.96);
				margin-top: 8px;
				&.date {
					font-size: 30px;
				}
				span {
					white-space: nowrap;
				}
			}
		}
	}
	.formContent {
		background-color: #f8f8f8;
		padding: 0 32px;
		@media (max-width: 960px) {
			padding: 0 15px;
		}
		.container {
			max-width: 560px;
			margin: 0 auto 0;
			padding: 35px 0 0;
			height: 100vh;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			overflow: auto;
			@media (max-width: 960px) {
				height: unset;
				min-height: 100vh;
			}
			.stepTitle {
				font-style: normal;
				font-weight: 600;
				font-size: 24px;
				line-height: 100%;
				letter-spacing: -0.02em;
				color: #000000;
				margin-bottom: 30px;
				@media (max-width: 600px) {
					margin-bottom: 30px;
				}
			}
			.formControls {
				margin-top: 24px;
				background: #ffffff;
				box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
				padding: 8px;
				display: flex;
				justify-content: space-between;
				@media (max-width: 600px) {
					margin-bottom: 10px;
					position: fixed;
					bottom: 10px;
					left: 35px;
					right: 35px;
					z-index: 100;
				}
				.nextStep,
				.submit {
					height: 60px;
					padding: 0 16px;
					display: flex;
					justify-content: center;
					align-items: center;
					background: #f4a700;
					font-style: normal;
					font-weight: 600;
					font-size: 14px;
					line-height: 140%;
					text-align: center;
					cursor: pointer;
					transition: 0.3s ease;
					span {
						margin-left: 16px;
					}
					&.disabled {
						background: #f2f2f2;
						color: #aeaeb2;
					}
				}
				.submit {
					flex-grow: 1;
					margin-left: 16px;
				}
				.prewStep {
					height: 60px;
					padding: 0 16px;
					display: flex;
					justify-content: center;
					align-items: center;
					font-style: normal;
					font-weight: 600;
					font-size: 14px;
					line-height: 140%;
					text-align: center;
					cursor: pointer;
					transition: 0.3s ease;
				}
			}
		}
	}
	.closeWrp {
		padding-top: 6px;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		@media (max-width: 960px) {
			order: -1;
			padding-top: 0;
			align-items: center;
			justify-content: space-between;
			padding-left: 32px;
		}
		.ic-close-rounded {
			width: 46px;
			height: 46px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
		}
		.mobStepInfo {
			display: none;
			@media (max-width: 960px) {
				display: flex;
				align-items: center;
			}
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 100%;
			text-align: center;
			color: #000000;
		}
	}
}
.formSuccess {
	display: grid;
	grid-template-columns: 56px 1fr 56px;
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background: #f4a700;
	@media (max-width: 1200px) {
		grid-template-columns: 1fr;
	}
	.closeWrp {
		padding-top: 6px;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		@media (max-width: 1200px) {
			display: none;
		}
		.ic-close-rounded {
			width: 46px;
			height: 46px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
		}
	}
	.content {
		background-color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
		@media (max-width: 1200px) {
			padding: 0 15px;
		}
		.container {
			width: calc(100vw - 30px);
			max-width: 560px;
			.icn {
				margin: 0 auto;
				display: block;
				width: 72px;
			}
			.title {
				font-style: normal;
				font-weight: 600;
				font-size: 32px;
				line-height: 100%;
				text-align: center;
				color: #f4a700;
				margin-top: 32px;
				@media (max-width: 1200px) {
					font-size: 38px;
				}
			}
			.sub {
				font-style: normal;
				font-weight: 600;
				font-size: 16px;
				line-height: 130%;
				text-align: center;
				color: #000000;
				margin-top: 16px;
			}
			.tabl {
				margin-top: 32px;
				border: 1px solid #8e8e93;
				box-sizing: border-box;
				* + .cell {
					border-top: 1px solid #8e8e93;
				}
				.cell {
					display: grid;
					grid-template-columns: 25% 1fr;
					grid-gap: 32px;
					padding: 8px;
					.caption {
						font-style: normal;
						font-weight: 600;
						font-size: 14px;
						line-height: 130%;
						color: #8e8e93;
					}
					.t1 {
						font-style: normal;
						font-weight: 600;
						font-size: 14px;
						line-height: 150%;
						color: #000000;
					}
					.t2 {
						font-style: normal;
						font-weight: 600;
						font-size: 30px;
						line-height: 135%;
						color: rgba(0, 0, 0, 0.96);
					}
				}
				.mapsLink {
					margin: 8px;
					display: block;
					border: 2px solid #f4a700;
					box-sizing: border-box;
					display: flex;
					align-items: center;
					padding: 16px;
					text-decoration: none;
					font-style: normal;
					font-weight: 600;
					font-size: 14px;
					line-height: 130%;
					color: #000000;
					.ic-marker {
						font-size: 26px;
						color: #f4a700;
						margin-right: 14px;
					}
				}
			}
			.controls {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-gap: 16px;
				margin-top: 32px;
				@media (max-width: 1200px) {
					grid-template-columns: 1fr;
				}
				.toHome,
				.toPage {
					height: 59px;
					line-height: 59px;
					font-style: normal;
					font-weight: 600;
					font-size: 13px;
					text-align: center;
					color: #000000;
					text-decoration: none;
				}
				.toHome {
					background: #e5e5ea;
				}
				.toPage {
					background: #f4a700;
				}
			}
		}
	}
}
.exitModal {
	position: fixed;
	bottom: 0;
	top: 0;
	right: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.6);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 105;
	.modalbody {
		background: #ffffff;
		box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: 500px;
		height: 100vh;
		max-height: 377px;
		padding: 24px;
		padding-top: 32px;
		box-sizing: border-box;
		.icon {
		}
		.t1 {
			font-weight: 600;
			font-size: 24px;
			line-height: 120%;
			text-align: center;
			letter-spacing: -0.02em;
			color: #000000;
			margin-top: 32px;
		}
		.t2 {
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 140%;
			text-align: center;
			color: #000000;
			margin-top: 16px;
		}
		.controls {
			display: flex;
			justify-content: flex-end;
			align-items: flex-end;
			width: 100%;
			flex-grow: 1;
			.btnExit {
				background: #e5e5ea;
				padding: 16px;
				font-style: normal;
				font-weight: 600;
				font-size: 13px;
				line-height: 140%;
				text-align: center;
				color: #000000;
				cursor: pointer;
			}
			.btnStay {
				background: #f4a700;
				padding: 16px;
				font-style: normal;
				font-weight: 600;
				font-size: 13px;
				line-height: 140%;
				text-align: center;
				color: #000000;
				margin-left: 8px;
				cursor: pointer;
			}
		}
	}
}
</style>