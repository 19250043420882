<template>
	<div>
		<div class="stepTitle">1: {{$t('form.steps.contactTitle')}}</div>
		<default-input
			:placeholder="$t('form.steps.nameInput')"
			@change="onFieldChange"
			propname="name"
			:validate="$v.name"
			:showError="showError"
			:prevalue="name"
		></default-input>
		<phone-input
			:placeholder="$t('form.steps.phoneInput')"
			@change="onPhoneChange"
			propname="phone"
			:validate="$v.phone"
			:showError="showError"
			:prevalue="phone"
		></phone-input>
		<div class="formControls">
			<div>
			</div>
			<div>
				<div class="nextStep" @click="onSubmit">
					{{$t('form.next')}} <span class="ic-arrow-long-rigth"></span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import DefaultInput from "@/components/common/inputs/DefaultInput.vue";
import PhoneInput from "@/components/common/inputs/PhoneInput.vue";
import { required, minLength } from "vuelidate/lib/validators";
export default {
	components: { DefaultInput, PhoneInput },
	props: ['initialFields'],
	name: "NamePhoneSlide",
	data() {
		return {
			showError: false,

			name: "",
			phone: "",

			phoneLength: 0,
		};
	},
	methods: {
		onFieldChange(e) {
			this[e.name] = e.value;
			// this.triggerChange();
		},
		onPhoneChange(e) {
			this[e.name] = e.value;
			this.phoneLength = e.length;
			// this.triggerChange();
		},
		triggerChange() {
			// debugger
			if (!this.$v.name.$invalid && !this.$v.phone.$invalid) {
				this.$emit("change", { name: this.name, phone: this.phone });
			} else {
				this.$emit("change", { name: "", phone: "" });
			}
		},
		onSubmit(){
			this.showError = true;
			if (!this.$v.name.$invalid && !this.$v.phone.$invalid) {
				this.$emit("submitslide", { name: this.name, phone: this.phone });
			}
		}
	},
	mounted () {
		this.name = this.initialFields ? this.initialFields.name : '';
		this.phone = this.initialFields ? this.initialFields.phone : '';
	},
	validations() {
		return {
			name: {
				required,
				minLength: minLength(4),
			},

			phone: {
				required,
				minLength: minLength(this.phoneLength),
			},
		};
	},
};
</script>

<style lang="scss">
</style>