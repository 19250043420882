<template>
    <div>
        <div class="stepTitle">3: {{ $t("form.steps.calendarTitle") }}</div>
        <Flickity ref="flickity" :options="sliderOptions" class="mounth">
            <div
                class="item"
                :class="{ active: currentMounth == index }"
                v-for="(item, index) in mounts"
                :key="index"
            >
                {{ formatDate(item) }}
            </div>
        </Flickity>
        <calendar
            :date="currentMounthItem"
            @select="onDateSelect"
            :initialDate="initialData"
            :workingDays="dateData"
            :loading="loading"
        ></calendar>
        <div class="formControls">
            <div>
                <div class="prewStep" @click="$emit('backStep')">
                    <span class="ic-arrow-long-left"></span>
                </div>
            </div>
            <div>
                <div
                    class="nextStep"
                    :class="{ disabled: !selectedDate }"
                    @click="onSumbit"
                >
                    {{ $t("form.next") }}
                    <span class="ic-arrow-long-rigth"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Flickity from "vue-flickity";
import Calendar from "../inputs/Calendar.vue";
export default {
    name: "CalendarPickSlide",
    props: {
        initialData: Date,
        mounthCount: { default: 4 },
        plasmacenter: Object,
        datesjson: String
    },
    components: {
        Flickity,
        Calendar
    },
    data() {
        return {
            sliderOptions: {
                cellAlign: "left",
                contain: true,
                pageDots: false,
                prevNextButtons: false,
                imagesLoaded: true,
                currentImg: 0
            },
            mountsCount: 4,
            mounts: [],
            currentMounth: 0,
            selectedDate: null,
            dateData: {},
            loading: true
        };
    },
    methods: {
        formatDate(date) {
            return Intl.DateTimeFormat(this.$i18n.locale, {
                year: "numeric",
                month: "long"
            }).format(new Date(date));
        },
        onSumbit() {
            if (this.selectedDate) {
                this.$emit("submitslide", {
                    selectedDate: this.selectedDate,
                    datesjson: JSON.stringify(this.workingDays)
                });
            }
        },
        onDateSelect(e) {
            this.selectedDate = e.date;
            this.$emit("pushspots", e);
        },
        updateDays(days) {
            days.forEach(item => {
                this.dateData[item.date] = item;
            });
        },
        loadData(dataStr) {
            this.loading = true;
            this.axios
                .post(`/api/easyweek/get-available-date-slots`, {
                    plasmacenter_id: this.plasmacenter.model.id,
                    forcibly: false,
                    date: dataStr
                })
                .then(response => {
                    this.updateDays(response.data.data.days);
                    this.loading = false;
                });
        }
    },
    computed: {
        currentMounthItem() {
            return this.mounts[this.currentMounth];
        }
    },
    created() {
        this.loading = true;
        this.axios
            .post(`/api/easyweek/get-available-date-slots`, {
                plasmacenter_id: this.plasmacenter.model.id,
                forcibly: false
            })
            .then(response => {
                if (this.datesjson != null) {
                    if (
                        JSON.stringify(response.data.data.days) ==
                        this.datesjson
                    ) {
                        this.axios
                            .post(`/api/easyweek/get-available-date-slots`, {
                                plasmacenter_id: this.plasmacenter.model.id,
                                forcibly: true
                            })
                            .then(forceresponse => {
                                this.updateDays(forceresponse.data.data.days);
                                this.loading = false;
                                // this.dateData = forceresponse.data.data;
                            });
                    } else {
                        this.updateDays(response.data.data.days);
                        this.loading = false;
                        // this.dateData = response.data.data;
                    }
                } else {
                    this.updateDays(response.data.data.days);
                    this.loading = false;
                    // this.dateData = response.data.data;
                }
            });
        for (let index = 0; index < this.mountsCount; index++) {
            let d = new Date();
            d.setDate(1);
            d.setHours(0, 0, 0, 0);
            let putDate = new Date(d.setMonth(d.getMonth() + index));
            // if (this.initialData) {
            //     if (
            //         this.initialData.getMonth() == putDate.getMonth() &&
            //         this.initialData.getFullYear() == putDate.getFullYear()
            //     )
            //         this.currentMounth = index;
            // }
            this.mounts.push(putDate);
        }
        if (this.initialData) {
            this.selectedDate = this.initialData;
        }
    },
    mounted() {
        this.$refs.flickity.on(
            "staticClick",
            (event, pointer, cellElement, cellIndex) => {
                if (cellIndex != undefined) {
                    this.currentMounth = cellIndex;
                    this.loadData(
                        this.$moment(this.currentMounthItem).format(
                            "YYYY-MM-01"
                        )
                    );
                }
            }
        );
    }
};
</script>

<style lang="scss">
.mounth {
    .item {
        text-transform: capitalize;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        color: #000000;
        padding: 10px 0;
        min-width: 162px;
        box-shadow: 0 0 0 1px inset #8e8e93;
        margin-right: 8px;
        transition: 0.3s ease;
        &:hover {
            box-shadow: 0 0 0 1px inset #f4a700;
        }
        &.active {
            box-shadow: 0 0 0 2px inset #f4a700;
        }
    }
}
</style>
