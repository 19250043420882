<template>
    <div class="s1">
        <div class="stepTitle above">4: {{ $t("form.steps.timeTitle") }}</div>
        <p class="inf">
            <b>
                <template v-if="centerId == 4">
                    Уточнити вільний час - 0800 600 003
                </template>
                <template v-else>
                    {{ $t("form.timeSelectHint") }}
                </template>
            </b>
        </p>
        <div class="scroller">
            <div class="timeGrid">
                <template v-for="(item, index) in times">
                    <div
                        class="item"
                        :key="item.text"
                        :class="{
                            active: index == selectedTimeIndex,
                            disabled: item.disabled
                        }"
                        @click="onTimeClick(index, item.disabled)"
                    >
                        {{ item.text }}
                    </div>
                </template>
            </div>
        </div>
        <div class="formControls">
            <div>
                <div class="prewStep" @click="$emit('backStep')">
                    <span class="ic-arrow-long-left"></span>
                </div>
            </div>
            <div style="flex-grow: 1">
                <div
                    class="submit"
                    :class="{ disabled: selectedTimeIndex == null }"
                    @click="onSubmit"
                >
                    {{ $t("form.submit") }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TimeSelectSlide",
    props: {
        spots: {
            type: Array,
            default: null
        },
        interval: {
            type: [Array, Object],
            default: null
        },
        centerId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            times: [],
            timeStart: 8,
            timeEnd: 18,
            selectedTimeIndex: null
        };
    },
    methods: {
        onTimeClick(index, disabled) {
            if (disabled == false) {
                this.selectedTimeIndex = index;
                this.$emit("change", this.times[this.selectedTimeIndex].text);
            }
        },
        onSubmit() {
            if (this.selectedTimeIndex != null) {
                this.$emit(
                    "submitslide",
                    this.times[this.selectedTimeIndex].text
                );
            }
        },

        Func(index, minute, sArray) {
            let a = `${index.toString().padStart(2, "0")}:${minute}`;
            if (sArray.length > 0) {
                if (sArray[0].start == a) {
                    sArray.shift();
                    this.times.push({
                        text: a,
                        disabled: false
                    });
                } else {
                    this.times.push({
                        text: a,
                        disabled: true
                    });
                }
            } else {
                this.times.push({
                    text: a,
                    disabled: true
                });
            }
        }
    },
    created() {
        let s = this.spots.slice();
        for (
            let index = this.interval.start;
            index <= this.interval.end;
            index++
        ) {
            // debugger;
            this.Func(index, "00", s);
            this.Func(index, "10", s);
            this.Func(index, "20", s);
            this.Func(index, "30", s);
            this.Func(index, "40", s);
            this.Func(index, "50", s);
        }
    }
};
</script>

<style lang="scss">
.s1 {
    display: flex;
    flex-direction: column;
    @media (max-width: 960px) {
        display: block;
    }
    .scroller {
        flex: 1 1 auto;
        overflow-y: auto;
        overflow-x: hidden;
        height: 0px;
        @media (max-width: 960px) {
            height: unset;
            padding-bottom: 50px;
        }
    }
}
.above {
    margin-bottom: 24px !important;
}
.timeGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    .item {
        height: 70px;
        box-shadow: 0 0 0 1px inset #aeaeb2;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #262628;
        cursor: pointer;
        transition: 0.3s ease;
        &:hover {
            box-shadow: 0 0 0 1px inset #f4a700;
        }
        &.active {
            box-shadow: 0 0 0 1px inset #f4a700;
            background-color: #f4a700;
        }
        &.disabled {
            box-shadow: unset;
            background-color: #f2f2f2;
            color: #aeaeb2;
            cursor: not-allowed;
        }
    }
}
</style>
