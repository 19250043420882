<template>
    <div
        class="day"
        :class="{
            selected: isSelected,
            current: isCurrentDay,
            disabled: isDisabled
        }"
        @click="
            $emit('click', { date: date, spots: spots, interval: interval })
        "
    >
        <span>{{ dateTxt }}</span>
    </div>
</template>

<script>
export default {
    name: "DayCell",
    props: ["date", "selectedDate", "workingDays"],
    data() {
        return {
            isWorkingDay: null,
            spots: null,
            interval: null
        };
    },
    mounted() {
        setTimeout(() => {
            let s = `${this.date.getFullYear()}-${Intl.DateTimeFormat(
                this.$i18n.locale,
                {
                    month: "2-digit"
                }
            ).format(this.date)}-${Intl.DateTimeFormat(this.$i18n.locale, {
                day: "2-digit"
            }).format(this.date)}`;

            if (this.workingDays[s]) {
                this.isWorkingDay = true;
                this.spots = this.workingDays[s].spots;
                this.interval = {
                    start: +this.workingDays[s].intervals[0].start.split(
                        ":"
                    )[0],
                    end: +this.workingDays[s].intervals[0].end.split(":")[0]
                };
            }
        }, 200);

        // for (let i = 0; i < this.workingDays.length; i++) {
        //     const item = this.workingDays[i];
        //     if (item.date == s) {
        //         this.isWorkingDay = true;
        //         this.spots = item.spots;
        //         this.interval = {
        //             start: +item.intervals[0].start.split(":")[0],
        //             end: +item.intervals[0].end.split(":")[0]
        //         };
        //         break;
        //     }
        // }
    },
    computed: {
        dateTxt() {
            return this.date.getDate();
        },
        isSelected() {
            return (
                Intl.DateTimeFormat("uk", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric"
                }).format(new Date(this.date)) ==
                Intl.DateTimeFormat("uk", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric"
                }).format(new Date(this.selectedDate))
            );
        },
        isDisabled() {
            let a = new Date();
            return (
                this.date <
                    new Date(
                        `${a.getMonth() + 1}.${a.getDate()}.${a.getFullYear()}`
                    ) || !this.isWorkingDay
            );
        },
        isCurrentDay() {
            return (
                Intl.DateTimeFormat("uk", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric"
                }).format(new Date(this.date)) ==
                Intl.DateTimeFormat("uk", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric"
                }).format(new Date())
            );
        }
    },
    methods: {}
};
</script>

<style lang="scss">
.day {
    position: relative;
    max-height: 48px;
    &:before {
        content: "";
        height: 100%;
        width: 100%;
        max-height: 48px;
        max-width: 48px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 0;
        transform: translate(-50%, -50%);
        cursor: pointer;
        transition: 0.3s ease;
    }
    &.selected {
        &:before {
            background: #f4a700;
        }
    }
    &.disabled {
        pointer-events: none;
        color: #aeaeb2;
    }
    span {
        position: relative;
        z-index: 2;
        cursor: pointer;
    }
    &:hover,
    &.current {
        &:before {
            box-shadow: 0 0 0 1px inset #f4a700;
        }
    }
}
</style>
