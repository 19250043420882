<template>
	<div class="s1">
		<div class="stepTitle">2: {{ $t("form.steps.centerTitle") }}</div>
		<div class="searchWrp">
			<span class="ic-lupa"></span>
			<input type="text" v-model.trim="search" />
		</div>
		<div class="centersWrp">
			<template v-if="centersToView">
				<div
					class="plasmacenterItem"
					v-for="item in centersToView"
					:key="item.model.id"
					@click="onItemSelect(item.UId)"
				>
					<div>
						<div class="name">{{ item.translate.title }}</div>
						<div class="pos icf">
							<p>{{ item.translate.address }}</p>
							<p>{{ item.translate.schedule }}</p>
						</div>
					</div>
					<div>
						<div class="selectP">
							<span
								v-if="item.UId == selectedUId"
								class="ic-bx_bx-check"
							></span>
							<template v-else> {{ $t("form.steps.select") }} </template>
						</div>
					</div>
				</div>
			</template>
			<template v-else>
				<div v-if="search != ''">{{ $t("form.steps.notfound") }}</div>
				<div v-else>plasmacenters empty</div>
			</template>
		</div>
		<div class="formControls">
			<div>
				<div class="prewStep" @click="$emit('backStep')">
					<span class="ic-arrow-long-left"></span>
				</div>
			</div>
			<div>
				<div
					class="nextStep"
					:class="{ disabled: selectedUId == null }"
					@click="onSubmit"
				>
					{{ $t("form.next") }} <span class="ic-arrow-long-rigth"></span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "PlasmacentersSlide",
	props: ["initialData"],
	data() {
		return {
			centers: [],
			search: "",
			selectedUId: null,
		};
	},
	methods: {
		onItemSelect(id) {
			this.selectedUId = id;
			this.onSubmit();
		},
		onSubmit() {
			if (this.selectedUId != null) {
				this.$emit("submitslide", this.centers[this.selectedUId]);
			}
		},
	},
	computed: {
		centersToView() {
			if (this.search != "") {
				let a = [];
				this.centers.forEach((item) => {
					if (
						item.translate.title.toLowerCase().match(this.search.toLowerCase())
					)
						a.push(item);
				});
				return a;
			} else return this.centers;
		},
	},
	created() {
		this.axios
			.post("/api/easyweek/get-plasmacenters", { lang: this.$i18n.locale })
			.then((response) => {
				this.centers = response.data.data.models;
				this.centers.forEach((item, idx) => {
					item.UId = idx;
				});
				if (this.initialData) {
					this.centers.forEach((item, idx) => {
						if (this.initialData.model.id == item.model.id)
							this.selectedUId = idx;
					});
				}
			});
	},
};
</script>

<style lang="scss">
.s1 {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding-bottom: 10px;
	.centersWrp {
		flex: 1 1 auto;
		overflow-y: auto;
		overflow-x: hidden;
		height: 0px;
		padding-right: 6px;
		@media (max-width: 960px) {
			display: block;
			height: unset;
		}
	}
	.searchWrp {
		position: relative;
		input {
			height: 27px;
			background-color: transparent;
			padding: 0 0 0 27px;
			width: 100%;
			border: none;
			box-shadow: inset 0px -1px 0px #000000;
			outline: none;
			box-sizing: border-box;
		}
		.ic-lupa {
			position: absolute;
			top: 50%;
			left: 0;
			transform: translate(0, -50%);
		}
	}
	.plasmacenterItem {
		display: grid;
		grid-template-columns: 1fr 100px;
		padding: 16px 0;
		grid-gap: 32px;
		border-bottom: 1px solid #000;
		cursor: pointer;
		.name {
			font-style: normal;
			font-weight: 600;
			font-size: 20px;
			line-height: 120%;
			letter-spacing: -0.02em;
			color: #000000;
			margin-bottom: 8px;
		}
		.pos {
			position: relative;
			padding-left: 30px;
			&::before {
				content: "\e91e";
				font-family: "biopharma" !important;
				speak: never;
				font-style: normal;
				font-weight: normal;
				font-variant: normal;
				text-transform: none;
				line-height: 1;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				font-size: 21px;
				color: #f4a700;
				position: absolute;
				top: 0;
				left: 0;
			}
			p {
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 170%;
				color: #000000;
				margin: 8px 0;
			}
		}
		.selectP {
			width: 100px;
			height: 48px;
			border: 1px solid #aeaeb2;
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 140%;
			text-align: center;
			color: #000000;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			transition: 0.3s ease;
			.ic-bx_bx-check {
				font-size: 24px;
			}
			&:hover {
				background: #f4a700;
				border-color: #f4a700;
			}
			&.active {
				background: #f4a700;
				border-color: #f4a700;
			}
		}
	}
}
</style>